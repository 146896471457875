import React from "react";
import { Logo } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import HospitalCard from "../components/HospitalCard";
import Loader from "../components/Loader";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "../components/ProfilePicture";
import { scanQueueClinincs } from "../lib/utils/constants";

const ChooseView = () => {
  const { userData } = useUserData();
  const { hospitalID, hospData, allDoctorsData, setDoctors } = useHospDocData();
  const navigate = useNavigate();
  console.log(allDoctorsData);

  return (
    <div className="flex flex-col w-full justify-center items-center pt-14 pb-40">
      <Logo />
      <div className="flex flex-col items-center space-y-3 mt-20 mb-16">
        <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl">
          Choose a View
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
        {/* Create constant */}
        {hospitalID === "fa577fb4-6353-44ae-9a41-0d362d0ab5ce"
          ? allDoctorsData?.slice(0, 2).map((doctor) => (
              <HospitalCard
                key={doctor.doctor_id}
                logo={doctor.profile_picture}
                name={doctor.full_name}
                docCount={"none"}
                handlePress={() => {
                  console.log(doctor.full_name);
                  setDoctors([doctor]);
                  navigate("/" + hospitalID + "/carousel-view");
                }}
              />
            ))
          : allDoctorsData?.map((doctor) => (
              <HospitalCard
                key={doctor.doctor_id}
                logo={doctor.profile_picture}
                name={doctor.full_name}
                docCount={"none"}
                handlePress={() => {
                  console.log(doctor.full_name);
                  setDoctors([doctor]);
                  navigate("/" + hospitalID + "/carousel-view");
                }}
              />
            ))}
      </div>
      {allDoctorsData && allDoctorsData.length > 1 && (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
          <button
            className="min-w-[320px] md:min-w-[360px] shadow-md rounded-lg hover:opacity-70"
            onClick={() => {
              setDoctors(
                hospitalID === "fa577fb4-6353-44ae-9a41-0d362d0ab5ce"
                  ? allDoctorsData?.slice(0, 2)
                  : allDoctorsData
              );
              navigate("/" + hospitalID + "/carousel-view");
            }}
          >
            <div className="flex flex-row items-center bg-white p-5 rounded-lg border-b-[1px] border-doctorsBorder">
              {hospData?.logo === "test" ? (
                <ProfilePicture
                  username={hospData.hospital_name}
                  className="w-14 h-14"
                />
              ) : (
                <img
                  className={`w-14 h-14 rounded-full`}
                  src={hospData?.logo}
                  alt="hospital-logo"
                ></img>
              )}
              <p className="font-semibold text-sbTextHover text-sm md:text-base ml-4">
                Carousel View
              </p>
            </div>
          </button>
          <button
            className="min-w-[320px] md:min-w-[360px] shadow-md rounded-lg hover:opacity-70"
            onClick={() => navigate("/" + hospitalID + "/choose-doctors")}
          >
            <div className="flex flex-row items-center bg-white p-5 rounded-lg border-b-[1px] border-doctorsBorder">
              {hospData?.logo === "test" ? (
                <ProfilePicture
                  username={hospData.hospital_name}
                  className="w-14 h-14"
                />
              ) : (
                <img
                  className={`w-14 h-14 rounded-full`}
                  src={hospData?.logo}
                  alt="hospital-logo"
                ></img>
              )}
              <p className="font-semibold text-sbTextHover text-sm md:text-base ml-4">
                Column View
              </p>
            </div>
          </button>

          {/* Inclinic and scan queue */}
          {hospitalID &&scanQueueClinincs.includes(hospitalID) && (
          <button
            className="min-w-[320px] md:min-w-[360px] shadow-md rounded-lg hover:opacity-70"
            onClick={() => navigate("/" + hospitalID + "/choose-doctor")}
          >
            <div className="flex flex-row items-center bg-white p-5 rounded-lg border-b-[1px] border-doctorsBorder">
              {hospData?.logo === "test" ? (
                <ProfilePicture
                  username={hospData.hospital_name}
                  className="w-14 h-14"
                />
              ) : (
                <img
                  className={`w-14 h-14 rounded-full`}
                  src={hospData?.logo}
                  alt="hospital-logo"
                ></img>
              )}
              <p className="font-semibold text-sbTextHover text-sm md:text-base ml-4">
                Clinic and Scan View
              </p>
            </div>
          </button>
          )}
        </div>
      )}

      {userData === undefined && <Loader />}
    </div>
  );
};

export default ChooseView;
