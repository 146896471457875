import React, { useEffect } from "react";
import { Logo } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import HospitalCard from "../components/HospitalCard";
import Loader from "../components/Loader";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChooseDoctor = () => {
  const { userData } = useUserData();
  const { hospitalID, allDoctorsData, doctor, setDoctor } = useHospDocData();
  const navigate = useNavigate();

//   useEffect(() => {
//     setDoctor(undefined);
//   }, []);

  return (
    <div className="flex flex-col w-full justify-center items-center pt-14 pb-40">
      <Logo />
      <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl mt-16 mb-5">
        Choose a doctor
      </p>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
        {/* Create constant */}
        {allDoctorsData?.map((doc) => (
          <HospitalCard
            key={doc.doctor_id}
            logo={doc.profile_picture}
            name={doc.full_name}
            docCount={"none"}
            handlePress={() => {
              console.log(doc.full_name);
              setDoctor(doc);
              navigate("/" + hospitalID + "/clinic-and-scan-view")
            }}
          />
        ))}
      </div>
      {userData === undefined && <Loader />}
    </div>
  );
};

export default ChooseDoctor;
