import React, {useState } from "react";
import moment from "moment";
import Loader from "../components/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useInterval } from "../lib/utils/useInterval";
import { useNavigate } from "react-router-dom";
import ClinicAndScanQueue from "../components/ClinicAndScanQueue";

const ClinicAndScanView = () => {
  const { userData } = useUserData();
  const { hospitalID, doctor } = useHospDocData();
  const navigate = useNavigate();

  const [time, setTime] = useState(moment().format("hh:mm A"));
  // console.log("doctors", doctors);

  useInterval(async () => {
    setTime(moment().format("hh:mm A"));
  }, 60000);

//   useEffect(() => {
//     if (doctor === undefined) navigate("/" + hospitalID);
//   }, [doctor]);

  return (
    <>
      {userData !== undefined ? (
        <div className="flex flex-col h-screen">
          <div className="flex flex-row justify-between mt-5">
            <p className="font-semibold text-2xl ml-10">{time}</p>
            <button onClick={() => navigate("/admin")} className="w-[8%] mr-10">
              <img
                src={require("../assets/images/DTlogo.png")}
                alt="Queue empty"
              />
            </button>
          </div>
          <div
            className={`grid grid-cols-1 md:grid-cols-1 ${
              doctor ? "lg:grid-cols-2" : "lg:grid-cols-3"
            } gap-5 pb-20`}
          >
            <ClinicAndScanQueue mapping_id={doctor?.mapping_id || ""} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ClinicAndScanView;
