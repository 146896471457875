import React from "react";

const Patient = ({
  pos,
  name,
  notStarted,
  onGoing,
  empty,
  text,
  type,
  patientKey,
}: {
  pos?: any;
  name?: string;
  notStarted?: boolean;
  onGoing?: boolean;
  empty?: boolean;
  text?: string;
  queue_type?: string;
  type?: string;
  patientKey?: number;
}) => {
  // console.log(empty);
  
  if (notStarted) {
    return (
      <div className="bg-darkBlue p-5 my-5 mx-36 rounded-xl">
        <div className="rounded-[1rem]">
          <p className="text-white text-xl">Queue has not started</p>
        </div>
      </div>
    );
  }

  if (onGoing) {
    return (
      <div className="bg-green flex flex-row justify-center items-center p-5 my-5 mx-36 rounded-xl lg:pl-28 ">
        {/* {(queue_type === "Token" || queue_type === "Slot_Token") && ( */}
        <p className="text-2xl flex-[0.6]  font-medium max-w-32 text-white text-left px-3  bg-green border-r border-white mr-12 ">
          {patientKey === 1 && "Ongoing"}
        </p>
        {pos && <p className="text-white flex-[0.6] text-3xl font-bold">Token {pos}</p>}
        {/* )} */}
        <p className="text-2xl flex-[0.6] ml-20 font-medium text-white">
          {name}
        </p>
        <p className="text-2xl flex-[0.6] font-medium text-white">
          {type === "booking" ? "Booking" : "Walk-in"}
        </p>
        
      </div>
    );
  }

  if (empty) {
    return (
      <div className="flex flex-col justify-center items-center bg-white rounded-lg mt-10 mx-20 p-5">
        <img
          src={require("../assets/images/empty.png")}
          alt="Queue empty"
          className="!w-[50%]"
        />
        <p className="text-center mb-5 font-medium">{text}</p>
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-row justify-center items-center p-5 my-5 mx-36 rounded-xl lg:pl-28">
      <p className="text-2xl flex-1 mr-12 border-r border-green text-left px-3  font-medium max-w-32  text-black ">
          {patientKey === 1 ? "Next" : "Inclinic"  }
      </p>
      {pos && <p className="text-green flex-[0.6] text-3xl font-bold">Token {pos}</p>}
      <p className="text-2xl flex-[0.6] ml-20 font-medium">{name}</p>
      <p
        className={
          "text-2xl flex-[0.6] font-medium " +
          (type === "booking" ? "text-red-500" : "text-blue-500")
        }
      >
        {" "}
        {type === "booking" ? "Booking" : "Walk-in"}
      </p>
    </div>

  );
};

export default Patient;
